<template>
  <div style="height: 100%">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="会员押金统计"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" class="searchbox" :inline="true">
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单编号" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="orderListData" height="calc(100% - 80px)" stripe style="width: 100%">
          <el-table-column prop="orderNum" label="订单编号" width="300px"> </el-table-column>
          <el-table-column prop="agent.name" label="所属代理商" align="center"> </el-table-column>
          <el-table-column prop="user.organization" label="所属机构">
            <template slot-scope="scope" v-if="scope.row.user.organization">
              <p>{{ scope.row.user.organization.name || "-" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="user.userName" label="会员名"> </el-table-column>
          <el-table-column prop="user.phone" label="会员账号"> </el-table-column>
          <el-table-column prop="paySuccessTime" label="支付时间" width="200px">
            <template slot-scope="scope">
              <p>{{ scope.row.paySuccessTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="upgradeType" label="升级类型">
            <template slot-scope="scope">
              <p v-if="scope.row.upgradeType == 1">自己升级</p>
              <p v-else>后台升级</p>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="金额（元）"> </el-table-column>
          <el-table-column label="开通时长" align="center">
            <template #default="scope">
              <p v-if="scope.row.payType == 1">{{ scope.row.payLength + "月" }}</p>
              <p v-if="scope.row.payType == 2">{{ scope.row.payLength + "季" }}</p>
              <p v-if="scope.row.payType == 3">{{ scope.row.payLength + "年" }}</p>
              <p v-if="scope.row.payType == 4">{{ scope.row.payLength + "天" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="payWay" label="支付方式">
            <template slot-scope="scope">
              <p v-if="scope.row.payWay == 0">余额</p>
              <p v-if="scope.row.payWay == 1">微信支付</p>
              <p v-if="scope.row.payWay == 2">支付宝</p>
              <p v-if="scope.row.payWay == 3">银行卡</p>
            </template>
          </el-table-column>
          <el-table-column prop="payType" label="开通类型">
            <template slot-scope="scope">
              <p v-if="scope.row.payType == 1">月付</p>
              <p v-if="scope.row.payType == 2">季付</p>
              <p v-if="scope.row.payType == 3">年付</p>
              <p v-if="scope.row.payType == 4">天付</p>
            </template>
          </el-table-column>
          <el-table-column prop="orderState" label="支付状态">
            <template slot-scope="scope">
              <el-tag type="waring" size="small" v-if="scope.row.orderState == 0">待支付</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.orderState == 1">已支付</el-tag>
              <el-tag type="info" size="small" v-if="scope.row.orderState == -1">取消支付</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.orderState == 2">支付失败</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="15" :current-page="PageIndex" :total="OrderTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '../../../components/HospitalDomain/User'
export default {
  name: "VipOrder",
  data() {
    var user = new User(this.TokenClient, this.Services.Authorization)
    return {
      User: user,
      orderListData: [],
      Keyword: "",
      OrderTotal: 0,
      PageIndex: 1,
      searchForm: {
        statrTime: "",
        endTime: "",
        keyWord: "",
        datepicker: "",
      },
    };
  },
  mounted() {
    this.orderList();
  },
  methods: {
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.orderList();
    },
    search() {
      this.PageIndex = 1;
      this.orderList();
    },
    orderList() {
      var _this = this;
      // _this.WechatPayDomain.OrderInfoAll(
      //   _this.searchForm.keyWord,
      //   _this.PageIndex,
      //   function (data) {
      //     _this.orderListData = data.data.results;
      //     _this.OrderTotal = data.data.dataTotal;
      //     _this.PageIndex = data.data.pageIndex;
      //   },
      //   function (err) {
      //     _this.orderListData = [];
      //   }
      // );
    },
  },
};
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}

.dialog-form {
  width: 430px;
}

/**/
.addhospital {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.addhospital .el-form {
  flex: 1;
}

.addhospital .form-box {
  padding: 30px 50px 30px 30px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.demo-drawer__footer {
  display: flex;
  margin-left: 100px;
}

.el-drawer /deep/ .el-drawer {
  width: 100vw;
}

.headImg {
  position: relative;
  margin-bottom: 25px;
}

.headimgBox {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid rgba(12, 154, 154, 0.28);
}

.headimgBox img {
  width: 100%;
}

.headImg .upload-btn {
  position: absolute;
  left: 57%;
  bottom: 0px;
}

.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url('../../../assets/img/wx_pay.jpg') top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
</style>
<style>
.staff .el-drawer {
  background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  overflow: scroll !important;
}
</style>